import React from "react"
import cx from "classnames"

import SEO from "../../components/seo"
import styles from "./job.module.css"
import styles2 from "./jobs.module.css"
import teamImg2 from "./team-2.jpg"

const JobPage = () => {
  return (
    <>
      <SEO title="Zákaznická podpora" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <img
            src={teamImg2}
            className={cx(styles.teamImg, styles2.teamImg)}
            alt="team image"
          />

          <h1 className={styles.textHeader}>Zákaznická podpora</h1>

          <p className={styles.text}>
            Jeden z nejrychleji rostoucích Foodtech startupů v Česku právě
            rozšiřuje svůj tým!
          </p>

          <p className={styles.text}>
            Vyrábíme stovky tisíc jídel měsíčně a jsme největší společnost v ČR,
            která poskytuje personalizované jídelníčky s kompletním servisem a
            dovozem až domů.
          </p>

          <p className={styles.text}>
            Našimi zákazníky jsou od mladých profesionálů, přes sportovce až k
            seniorům. Každému pomáháme dosáhnout jich cíle ať už je to cítit se
            lépe, redukovat váhu, nebo zlepšit jejich zdravotní stav.
          </p>

          <p className={styles.text}>
            Chceš být součástí dalšího success story českého startupového světa?
            Baví Tě zdravý životní styl? Sportuješ?
          </p>

          <p className={styles.text}>
            Staň se součástí našeho nadšeného týmu a dělej práci, která Ti bude
            dávat smysl!
          </p>

          <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              komunikovat <strong>telefonicky, emailem a přes chat</strong> s
              aktivními klienty
            </li>
            <li className={styles.newList}>
              předávat zpětnou vazbu klientů kolegům ze sales a výrobního
              oddělení
            </li>
            <li className={styles.newList}>
              používat automatizované CRM a interní systém pro zjištění všech
              detailů o volajícím klientovi
            </li>
            <li className={styles.newList}>
              <strong>rozvíjet svoje komunikační schopnosti</strong> s možností
              rozvoje a postupném přechodu do sales
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co od tebe očekáváme?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              dobré komunikační schopnosti, organizovanost a schopnost řešit
              problémy
            </li>
            <li className={styles.newList}>
              <strong>
                znalost anglického jazyka na úrovni B2+, znalost dalších jazyků
                je velkou výhodou
              </strong>{" "}
              (Němčina, Ruština, Polština)
            </li>
            <li className={styles.newList}>
              výhodou je alespoň rok na pozici operátor/operátorka se
              zkušenostmi z call centra nebo zákaznické péče (rádi zaškolíme
              kohokoliv, kdo je aktivní a komunikativní osobnost)
            </li>
            <li className={styles.newList}>
              baví tě zdravý životní styl a máš rád/a dobré jídlo
            </li>
            <li className={styles.newList}>
              <strong>
                nadšení, disciplínu a dochvilnost, našim klientům pomáháme vždy
                v PO až PÁ od 9:00 – 18:00
              </strong>
            </li>
            <li className={styles.newList}>
              uživatelská znalost práce v MS Office, nebo alternativy od Googlu
            </li>
            <li className={styles.newList}>
              češtinu na úrovni rodilého mluvčího
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co od nás získáš?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              spolupráce s ambiciózním týmem nadšenců
            </li>
            <li className={styles.newList}>
              motivační finanční ohodnocení a měsíční bonusy
            </li>
            <li className={styles.newList}>
              plně hrazené vzdělávací kurzy a konzultace s předními odborníky v
              oboru
            </li>
            <li className={styles.newList}>
              firemní obědy zdarma a multisport kartu
            </li>
            <li className={styles.newList}>
              moderní kanceláře v srdci Karlína
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>
            Těšíme se na tvoje CV a rádi tě uvítáme v našem týmu NutritionPro!
          </h3>
          <p className={styles.text}>Kontakty:</p>
          <a className={styles.text} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
        </div>
      </div>
    </>
  )
}

export default JobPage
